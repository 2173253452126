
import { defineComponent, ref, watchEffect, Ref } from "vue";
import DefaultLoader from "@/components/loader/DefaultLoader.vue";
import { apiPut } from "@/api";
import { ShowSuccess, Toast } from "@/utils/notify";

export default defineComponent({
  name: "EditableField",
  components: { DefaultLoader },
  emits: ["updated"],
  props: {
    dt: {
      required: true,
    },
    field: {
      type: String,
      required: true,
    },
    isText: {
      type: Boolean,
      default: false
    },
    url: {
      type: String,
      required: true,
    },
    parser: {
      type: Function,
      default: (data: string) => data,
    },
  },
  setup(props, context) {
    const loading: Ref<boolean> = ref(false);
    const isEditing: Ref<boolean> = ref(false);
    const mutatedData: Ref<any> = ref();

      watchEffect(() => {
      mutatedData.value = props.dt;
    });

    const Save = async () => {
      loading.value = true;
      try {
        await apiPut(props.url, {
          [props.field]: props.parser(mutatedData.value),
        });
        ShowSuccess();
        context.emit("updated", props.parser(mutatedData.value));
        isEditing.value = false;
      } catch (e:any) {
        Toast.fire({
          icon: "warning",
          title: "Unable to save: " + e.message,
        }).then();
      }
      loading.value = false;
    };

    return {
      loading,
      isEditing,
      mutatedData,
      Save,
    };
  },
});
