import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  colspan: "8",
  class: "text-center"
}
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "font-weight-normal" }
const _hoisted_4 = { class: "font-weight-normal" }
const _hoisted_5 = { style: {"min-width":"240px"} }
const _hoisted_6 = { class: "font-weight-normal" }
const _hoisted_7 = { style: {"min-width":"240px"} }
const _hoisted_8 = { class: "font-weight-normal" }
const _hoisted_9 = { class: "font-weight-normal" }
const _hoisted_10 = { class: "dropdown" }
const _hoisted_11 = ["disabled"]
const _hoisted_12 = { class: "dropdown-menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditableField = _resolveComponent("EditableField")!
  const _directive_admin = _resolveDirective("admin")!

  return (_openBlock(), _createElementBlock("tr", null, [
    (_ctx.loading)
      ? (_openBlock(), _createElementBlock("td", _hoisted_1, _cache[3] || (_cache[3] = [
          _createElementVNode("span", {
            class: "font-weight-bolder",
            style: {"font-size":"1.5em"}
          }, " Loading ... ", -1)
        ])))
      : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
          _createElementVNode("td", null, [
            _createElementVNode("a", _hoisted_2, _toDisplayString(_ctx.dt.id), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.dt.name), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.dt.code), 1)
          ]),
          _createElementVNode("td", _hoisted_5, [
            _createElementVNode("span", _hoisted_6, [
              _createVNode(_component_EditableField, {
                dt: _ctx.dt.we_buy,
                field: "we_buy",
                url: `/admin/fiats/${_ctx.dt.id}`,
                parser: (data) => Number(data),
                onUpdated: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('updated', { field: 'we_buy', value: $event })))
              }, null, 8, ["dt", "url", "parser"])
            ])
          ]),
          _createElementVNode("td", _hoisted_7, [
            _createElementVNode("span", _hoisted_8, [
              _createVNode(_component_EditableField, {
                dt: _ctx.dt.we_sell,
                field: "we_sell",
                url: `/admin/fiats/${_ctx.dt.id}`,
                parser: (data) => Number(data),
                onUpdated: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('updated', { field: 'we_sell', value: $event })))
              }, null, 8, ["dt", "url", "parser"])
            ])
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.dt.updated_at), 1)
          ]),
          _createElementVNode("td", null, [
            _createElementVNode("div", _hoisted_10, [
              _createElementVNode("button", {
                class: "btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0",
                type: "button",
                id: "dropdownMenuButton",
                "data-bs-toggle": "dropdown",
                "aria-expanded": "false",
                disabled: _ctx.loading
              }, _cache[4] || (_cache[4] = [
                _createElementVNode("span", { class: "icon icon-sm" }, [
                  _createElementVNode("span", { class: "fas fa-ellipsis-h icon-dark" })
                ], -1),
                _createElementVNode("span", { class: "sr-only" }, "Toggle Dropdown", -1)
              ]), 8, _hoisted_11),
              _createElementVNode("div", _hoisted_12, [
                _cache[6] || (_cache[6] = _createElementVNode("a", { class: "dropdown-item text-dark" }, [
                  _createElementVNode("span", { class: "fas fa-eye mr-1" }),
                  _createTextVNode(" Edit Currency ")
                ], -1)),
                _withDirectives((_openBlock(), _createElementBlock("a", {
                  class: "dropdown-item text-danger",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (
                _ctx.RequestConfirmation(
                  'Do you really want to delete this currency?',
                  () => {}
                )
              ))
                }, _cache[5] || (_cache[5] = [
                  _createElementVNode("span", { class: "fas fa-trash-alt mr-1" }, null, -1),
                  _createTextVNode(" Delete Currency ")
                ]))), [
                  [_directive_admin, 100]
                ])
              ])
            ])
          ])
        ], 64))
  ]))
}