import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "mr-1" }
const _hoisted_2 = { class: "input-group mb-3" }
const _hoisted_3 = ["type"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultLoader = _resolveComponent("DefaultLoader")!

  return (_openBlock(), _createBlock(_component_DefaultLoader, { loading: _ctx.loading }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("span", _hoisted_1, [
        _renderSlot(_ctx.$slots, "default", {}, () => [
          _createTextVNode(_toDisplayString(_ctx.dt), 1)
        ])
      ], 512), [
        [_vShow, !_ctx.isEditing]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("input", {
          type: _ctx.isText ? 'text' : 'number',
          class: "form-control form-control-sm",
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.mutatedData) = $event)),
          "aria-label": "Value"
        }, null, 8, _hoisted_3), [
          [_vModelDynamic, _ctx.mutatedData],
          [_vShow, _ctx.isEditing]
        ]),
        _createElementVNode("button", {
          class: "btn btn-outline-success",
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.Save && _ctx.Save(...args)))
        }, _cache[4] || (_cache[4] = [
          _createElementVNode("i", { class: "fas fa-check" }, null, -1)
        ])),
        _createElementVNode("button", {
          class: "btn btn-outline-danger",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isEditing = false))
        }, _cache[5] || (_cache[5] = [
          _createElementVNode("i", { class: "fas fa-times" }, null, -1)
        ]))
      ], 512), [
        [_vShow, _ctx.isEditing]
      ]),
      _withDirectives(_createElementVNode("button", {
        class: "btn btn-sm btn-neutral",
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isEditing = true))
      }, _cache[6] || (_cache[6] = [
        _createElementVNode("i", { class: "fas fa-edit" }, null, -1)
      ]), 512), [
        [_vShow, !_ctx.isEditing]
      ])
    ]),
    _: 3
  }, 8, ["loading"]))
}